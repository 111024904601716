<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand>
        <b-img
          :src="dDotsHeaderLogo"
          sizes="40"
          rounded
          fluid
          class="brand-header"
          alt=""
        >
        </b-img>
      </b-navbar-brand>
      <!-- <div class="select-lang w-100">
        <b-form-select
          v-model="language"
          :options="languageList"
          class="btn-select"
        ></b-form-select>
      </div> -->
    </b-navbar>
    <div class="p-4" v-if="!dataReady">
      <div class="p-4 bg-white">
        <div class="text-center">
          <b-spinner v-if="isBusy"></b-spinner>
        </div>
        <b-row v-if="!isBusy">
          <b-col sm="3">
            <b-img
              :src="data.brandLogo ? data.brandLogo : dDotsBodyLogo"
              sizes="40"
              rounded
              fluid
              alt=""
            ></b-img>
          </b-col>
          <b-col sm="9">
            <div class="text-header"><b>กำลังชำระเงินไปที่</b></div>
            <div class="text-branch">
              <b>{{ data.branchName }}</b>
            </div>
            <b-row>
              <b-col sm="2" class="mt-1">Payment Name:</b-col>
              <b-col sm="10" class="mt-1">{{ data.paymentName }}</b-col>
              <b-col sm="2" class="mt-1">Order No:</b-col>
              <b-col sm="10" class="mt-1">{{ data.orderNo }}</b-col>
              <b-col sm="2" class="mt-1">Amount:</b-col>
              <b-col sm="10" class="mt-1">{{ amountShow }} THB</b-col>
              <b-col v-if="errorText">
                <div class="bg-error mt-4 px-3 py-2 d-inline-block">
                  <span
                    ><b-icon-exclamation-circle-fill></b-icon-exclamation-circle-fill>
                    {{ errorText }}.</span
                  >
                </div>
              </b-col>
              <b-col v-if="isPaid">
                <div class="bg-paid mt-4 px-3 py-2 d-inline-block">
                  <span
                    ><b-icon
                      icon="exclamation-triangle-fill"
                      variant="warning"
                    ></b-icon>
                    {{ warningText }}</span
                  >
                </div>
              </b-col>
              <b-col sm="12">
                <b-form-group class="mt-4">
                  <b-form-radio
                    v-for="(item, index) in typeList"
                    :key="index"
                    v-model="data.type"
                    name="paymentType"
                    :value="item.value"
                    button-variant="danger"
                    @change="changeTypePayment"
                    >ㅤ<span>{{ item.text }}</span>
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            v-if="data.type == 'promptpay' || data.type == 'rabbit_linepay'"
          >
            <div class="mt-4 text-center">
              <img :src="urlImage" height="400" />
            </div>
          </b-col>
          <b-col sm="12" v-if="data.type == 'bill_payment'">
            <div class="px-4">
              <div class="mt-4 text-center">
                <img :src="urlImage" width="600" /><br />
                <span class="mt-2 text-detail"
                  >{{ refBill1 }} | {{ refBill2 }}</span
                >
              </div>
            </div>
          </b-col>
          <b-col sm="12">
            <div class="mt-4 text-center">
              <b-button
                size="lg"
                class="mx-3 btn-purple"
                @click="openOmise"
                :disabled="dataReady || isPaid"
                v-if="data.type == 'credit_card' || data.type == 'truemoney'"
              >
                ชำระเงิน
              </b-button>
              <b-button
                size="lg"
                class="btn-purple-outline"
                @click="goBackToUrl"
              >
                ยกเลิก
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      v-model="showModal"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="goBackToUrl()"
    >
      <div class="text-center">
        <b-spinner v-if="showLoadingModal" variant="dark"></b-spinner>
        <div v-if="!showLoadingModal">
          <b-icon
            icon="check-circle-fill"
            scale="2"
            shift-v="4"
            variant="success"
          ></b-icon
          ><span class="font-weight-bold text-header-success"
            >ㅤชำระเงินสำเร็จ!</span
          >
          <p class="mt-4">กำลังกลับไปที่ร้านค้าในอีก {{ countDown }} วินาที</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      dataReady: true,
      data: {},
      token: "",
      amountShow: 0,
      typeList: [],
      phoneNumber: "0895292123",
      brandLogo: "",
      urlImage: "",
      refBill1: "",
      refBill2: "",
      isPaid: false,
      showLoadingModal: false,
      showModal: false,
      countDown: 5,
      dDotsHeaderLogo: "./image/headerlogo.png",
      dDotsBodyLogo: "./image/bodylogo.png",
      errorText: "",
      warningText: "",
      language: "th",
      languageList: [
        { value: "th", text: "TH Language" },
        { value: "en", text: "EN Language" }
      ],
      isBusy: false,
      ngRok: "https://31e6-125-25-92-210.ngrok.io"
    };
  },
  created: function() {
    this.isBusy = true;
    var url = new URL(window.location.href);
    this.token = url.searchParams.get("tk");
    this.axios
      .post(`${this.$apiUrl}/api/Service/ReceiveData?data=${this.token}`, {})
      .then(({ data }) => {
        this.amountShow = data.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        this.typeList = [];
        this.data = data;
        this.data.orderStatus = "expired";
        this.data.amount = data.amount * 100;
        this.data.publicKey = data.pk;
        this.data.secretKey = data.sk;
        this.data.BackendReturnURL = "https://www.google.com/";
        this.setTypeList();
        this.changeTypePayment();
      })
      .catch(error => {
        this.isBusy = false;
        this.dataReady = true;
        alert("ข้อมูลไม่ถูกต้อง");
      });
  },
  methods: {
    openOmise() { // create UI omise payment
      OmiseCard.configure({
        publicKey: this.data.publicKey
      });
      OmiseCard.open({
        amount: this.data.amount,
        currency: "THB",
        defaultPaymentMethod: this.data.type,
        onCreateTokenSuccess: nonce => {
          this.showLoadingModal = true;
          this.showModal = true;
          if (nonce.startsWith("tokn_")) {
            this.axios
              .post(`${this.$apiUrl}/api/Service/ChargesCreateWithToken`, {
                PublicKey: this.data.publicKey,
                SecretKey: this.data.secretKey,
                UserGUID: this.data.userGUID,
                CustomerId: this.data.CustomerId,
                CardId: this.data.CardId,
                Amount: this.data.amount,
                Token: nonce,
                type: this.data.type,
                OrderId: this.data.orderId,
                OrderNo: this.data.orderNo,
                AdditionalData: this.data.additionalData
              })
              .then(({ data }) => {
                if (this.data.type == "credit_card") {
                  this.showLoadingModal = false;
                  if (!data.failMessages) {
                    this.errorText = "";
                    this.countDownModal();
                  } else {
                    this.errorText = data.failMessages;
                  }
                }
              });
          } else {
            if (this.data.type == "truemoney") {
              this.data.PhoneNumber = this.phoneNumber;
              this.axios
                .post(
                  `${this.$apiUrl}/api/Service/CreateTrueMoneyCharges`,
                  this.data
                )
                .then(({ data }) => {
                  this.showLoadingModal = false;
                  this.isBusy = false;
                  this.dataReady = false;
                });
            }
          }
        },
        onFormClosed: () => {}
      });
    },
    setTypeList() {
      this.data.typeList.forEach(e => {
        if (e == "credit_card") {
          this.typeList.push({ text: "เครดิตการ์ด / เดบิตการ์ด", value: e });
        }
        if (e == "promptpay") {
          this.typeList.push({ text: "พร้อมเพย์", value: e });
        }
        if (e == "truemoney") {
          this.typeList.push({ text: "ทรูมันนี่วอลเล็ท", value: e });
        }
        if (e == "bill_payment") {
          this.typeList.push({ text: "บิลเพย์เมนต์", value: e });
        }
        if (e == "rabbit_linepay") {
          this.typeList.push({ text: "แรบบิทไลน์เพย์", value: e });
        }
        if (e == "internet_banking") {
          this.typeList.push({ text: "อินเตอร์เน็ตแบงก์กิ้ง", value: e });
        }
        if (e == "points_citi") {
          this.typeList.push({ text: "เพย์วิธพอยท์", value: e });
        }
      });
    },
    changeTypePayment() { // change type
      this.isBusy = true;
      this.isPaid = false;
      this.urlImage = "";
      if (
        this.data.orderStatus == "Successful" ||
        this.data.orderStatus == "Reversed" ||
        this.data.orderStatus == "Pending"
      ) {
        this.warningText =
          this.data.orderStatus == "Successful"
            ? "รายการนี้ชำระเงินเรียบร้อยแล้ว"
            : "รายการนี้อยู่ระหว่างรอการชำระเงินจากที่อื่น";
        this.isBusy = false;
        this.dataReady = false;
        this.isPaid = true;
        return;
      }
      if (this.data.type == "promptpay") {
        this.axios
          .post(`${this.$apiUrl}/api/Service/CreatePromptpayCharges`, this.data)
          .then(({ data }) => {
            this.urlImage = data.downloadUri;
            this.isBusy = false;
            this.dataReady = false;
            this.paymentWait();
          });
      }
      if (this.data.type == "bill_payment") {
        this.axios
          .post(
            `${this.$apiUrl}/api/Service/CreateBillPaymentCharges`,
            this.data
          )
          .then(({ data }) => {
            this.urlImage = data.references.barcode;
            this.refBill1 = data.references.reference_number_1;
            this.refBill2 = data.references.reference_number_2;
            this.isBusy = false;
            this.dataReady = false;
            this.paymentWait();
          });
      }
      if (this.data.type == "rabbit_linepay") {
        this.axios
          .post(`${this.$apiUrl}/api/Service/CreateRabbitLinepayCharges`, data)
          .then(({ data }) => {
            this.isBusy = false;
            this.dataReady = false;
            this.paymentWait();
          });
      }
      if (
        this.data.type != "promptpay" &&
        this.data.type != "bill_payment" &&
        this.data.type != "rabbit_linepay"
      ) {
        this.isBusy = false;
        this.dataReady = false;
        if (!this.isPaid) {
          this.openOmise();
        }
      }
    },
    paymentWait() { // check payment status every 5 seconds
      this.axios
        .post(
          `${this.$apiUrl}/api/Service/CheckOrderStatus?order_no=${this.data.orderNo}`,
          {}
        )
        .then(({ data }) => {
          this.isBusy = false;
          this.dataReady = false;
          setTimeout(
            function() {
              if (data == "Pending") {
                this.paymentWait();
              } else if (data == "successful") {
                console.log(`successful`);
              }
            }.bind(this),
            5000
          );
        });
    },
    countDownModal() {
      if (this.countDown == 0) {
        this.showModal = false;
        this.goBackToUrl();
      }
      setTimeout(() => {
        this.countDown--;
        this.countDownModal();
      }, 1000);
    },
    goBackToUrl() { // return to before website.
      window.location.href = this.data.returnURL;
    }
  }
};
</script>

<style scoped>
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #f1f1f1;
  color: #333333;
}
.navbar.navbar-dark.bg-dark {
  background-color: #ffffff !important;
}
.select-lang {
  text-align: right;
  margin-right: 15px;
}
.brand-header {
  max-height: 40px;
  margin-left: 15px;
}
.btn-select {
  height: 42.4px;
  margin-top: 2px;
  color: #404040;
  background-color: white;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  padding: 9px 10px;
  cursor: pointer;
}
.text-header {
  font-size: 28px;
}
.text-detail {
  font-size: 20px;
}
.text-header-success {
  font-size: 32px;
}
.text-branch {
  font-size: 44px;
}
.btn-purple {
  background-color: #8f20c6;
  color: #ffffff;
  border: 1px solid #8f20c6;
}
.btn-purple-outline {
  background-color: #ffffff;
  color: #8f20c6;
  border: 1px solid #8f20c6;
}
.bg-white {
  border-radius: 2px;
}
.bg-error {
  background-color: #f8d7da;
  border: 1px solid #f5c3c8;
  color: #842845;
}
.bg-paid {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}
</style>
